import { onValue, ref, set } from 'firebase/database'
import { useEffect, useState } from 'react'
import { useDebounceValue } from 'usehooks-ts'
import { realtimeDatabase, realtimeDatabaseKeys } from '../firebase/config'
import { usePlaylistScrollSyncSettings } from './usePlaylistScrollSyncSettings'
import { Unsubscribe } from '@firebase/firestore'

export const useMultipleDeviceScrollSync = (playlistId: string | undefined) => {
  const { settings: playlistScrollSyncSettings } = usePlaylistScrollSyncSettings()

  const [scrollPosition, setScrollPosition] = useDebounceValue<number>(0, 250)

  const [unsubscribeToScrollChanges, setUnsubscribeToScrollChanges] = useState<Unsubscribe>()

  /**
   * Sync scroll position with other devices
   */
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollPosition(window.scrollY)
    })

    return () => {
      window.removeEventListener('scroll', () => {
        setScrollPosition(window.scrollY)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!playlistId || !playlistScrollSyncSettings.syncToOtherDevices) {
      return
    }

    void set(
      ref(realtimeDatabase, realtimeDatabaseKeys.playlistScrollPosition(playlistId)),
      scrollPosition
    )
  }, [scrollPosition, playlistId, playlistScrollSyncSettings.syncToOtherDevices])

  /**
   * Listen to scroll position changes from other devices
   */
  useEffect(() => {
    if (unsubscribeToScrollChanges) {
      unsubscribeToScrollChanges()
    }

    if (!playlistId || !playlistScrollSyncSettings.syncFromOtherDevices) {
      return
    }

    const playlistScrollPositionRef = ref(
      realtimeDatabase,
      realtimeDatabaseKeys.playlistScrollPosition(playlistId)
    )

    const unsubscribe = onValue(playlistScrollPositionRef, (snapshot) => {
      const currentScroll = snapshot.val() as number | null

      if (typeof currentScroll === 'number') {
        window.scrollTo({ top: currentScroll, behavior: 'smooth' })
      }
    })

    setUnsubscribeToScrollChanges(() => unsubscribe)

    return () => {
      unsubscribe()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistId, playlistScrollSyncSettings.syncFromOtherDevices])
}
