import { useEffect, useRef } from 'react'
import { usePlaylistCustomScrollSettings } from './usePlaylistCustomScrollSettings'

export const useCustomUpDownScrolling = () => {
  const { scrollDelta } = usePlaylistCustomScrollSettings()
  const scrollDeltaRef = useRef(scrollDelta)

  useEffect(() => {
    scrollDeltaRef.current = scrollDelta
  }, [scrollDelta])

  useEffect(() => {
    const fn = (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        event.preventDefault()
        window.scrollTo({
          top: window.scrollY - scrollDeltaRef.current,
          behavior: 'smooth'
        })
      } else if (event.key === 'ArrowDown') {
        event.preventDefault()
        window.scrollTo({
          top: window.scrollY + scrollDeltaRef.current,
          behavior: 'smooth'
        })
      }
    }

    window.addEventListener('keydown', fn)

    return () => {
      window.removeEventListener('keydown', fn)
    }
  }, [])
}
