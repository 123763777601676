import { FocusEvent, forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { BaseTextFieldProps, Stack, SxProps, TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { OutlinedInputProps } from '@mui/material/OutlinedInput'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import ConditionalTooltip from '../ConditionalTooltip'
import RemoveIcon from '@mui/icons-material/Remove'
import { formatNumber } from '../../../helpers/utils'

export interface NumberInputProps {
  InputProps?: Partial<OutlinedInputProps>
  min?: number
  max?: number
  onChange: (value: number) => void
  sx?: SxProps
  size?: BaseTextFieldProps['size']
  value: number | undefined
  step?: number
  allowFloatingPointNumbers?: boolean

  /**
   * The value to set when the input is cleared. When provided "clear" button will be shown.
   */
  valueWhenCleared?: number
}

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  function NumberInput(props, forwardedRef) {
    const {
      value,
      onChange,
      InputProps,
      min = 0,
      max,
      sx,
      size = 'small',
      valueWhenCleared,
      step = 1,
      allowFloatingPointNumbers
    } = props

    const ref = useRef<HTMLInputElement>(null)

    useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement)

    const setValueToInput = (valueToSet: number | undefined) => {
      if (ref.current) {
        ref.current.value =
          valueToSet !== undefined
            ? valueToSet === valueWhenCleared
              ? '-'
              : formatNumber(valueToSet, { addSpaces: false, maxPrecision: 2 })
            : ''
      }
    }

    const onChangeAndSetValueToInput = (newValue: number) => {
      onChange(newValue)
      setValueToInput(newValue)
    }

    /**
     * Set the value to the input when the value changes.
     * (It can be set also by some other component, that is why we need to react to its changes)
     */
    useEffect(() => {
      setValueToInput(value)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const onBlur = (event: FocusEvent<HTMLInputElement>) => {
      const newValue = allowFloatingPointNumbers
        ? parseFloat(event.target.value)
        : parseInt(event.target.value)

      if (isNaN(newValue)) {
        onChangeAndSetValueToInput(value || 0)
        return
      }

      if (min !== undefined && newValue < min) {
        onChangeAndSetValueToInput(min)
        return
      }

      if (max !== undefined && newValue > max) {
        onChangeAndSetValueToInput(max)
        return
      }

      onChangeAndSetValueToInput(newValue)
    }

    const onAdd = () => {
      const defaultValue = Math.max(min, step)
      const newValue =
        value !== undefined
          ? value === valueWhenCleared
            ? defaultValue
            : value + step
          : defaultValue

      // Ensure value doesn't go above max
      if (max !== undefined && newValue > max) {
        onChange(max)
      } else {
        onChange(newValue)
      }
    }

    const onSubtract = () => {
      const defaultValue = min !== undefined ? min : 0
      const newValue =
        value !== undefined
          ? value === valueWhenCleared
            ? defaultValue
            : value - step
          : defaultValue

      // Ensure value doesn't go below min
      if (newValue < min) {
        onChange(min)
      } else {
        onChange(newValue)
      }
    }

    const isDisabled = value === undefined
    const isAddDisabled = isDisabled || (max !== undefined && value >= max)
    const isSubtractDisabled = isDisabled || (min !== undefined && value <= min)
    const isClearDisabled = value === valueWhenCleared

    return (
      <Stack
        direction={'row'}
        alignItems={'center'}
        flexWrap={'nowrap'}
      >
        <TextField
          inputRef={ref}
          defaultValue={value}
          onBlur={onBlur}
          size={size}
          sx={{ minWidth: '3ch', maxWidth: '7ch', mr: 1, ...sx }}
          InputProps={{
            sx:
              size === 'small'
                ? {
                    fontSize: '0.8em'
                  }
                : undefined,
            ...InputProps
          }}
        />

        <IconButton
          onClick={onAdd}
          disabled={isAddDisabled}
          size={size}
        >
          <AddIcon />
        </IconButton>

        <IconButton
          onClick={onSubtract}
          disabled={isSubtractDisabled}
          size={size}
        >
          <RemoveIcon />
        </IconButton>

        {valueWhenCleared !== undefined && (
          <ConditionalTooltip
            disabled={isClearDisabled}
            title={'Vymazať'}
          >
            <IconButton
              onClick={() => onChange(valueWhenCleared)}
              disabled={isClearDisabled}
              size={size}
            >
              <ClearIcon />
            </IconButton>
          </ConditionalTooltip>
        )}
      </Stack>
    )
  }
)

export default NumberInput
