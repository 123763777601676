import { useLocalStorage } from 'usehooks-ts'
import { localStorageKeys } from './useLatestPlaylists'

export interface PlaylistScrollSyncSettings {
  syncFromOtherDevices: boolean
  syncToOtherDevices: boolean
}

export const usePlaylistScrollSyncSettings = () => {
  const [settings, setSettings] = useLocalStorage<PlaylistScrollSyncSettings>(
    localStorageKeys.playlistScrollSyncSettings,
    {
      syncFromOtherDevices: false,
      syncToOtherDevices: false
    }
  )

  const toggleSyncFromOtherDevices = () => {
    setSettings({
      ...settings,
      syncFromOtherDevices: !settings.syncFromOtherDevices
    })
  }

  const toggleSyncToOtherDevices = () => {
    setSettings({
      ...settings,
      syncToOtherDevices: !settings.syncToOtherDevices
    })
  }

  return { settings, toggleSyncFromOtherDevices, toggleSyncToOtherDevices, setSettings }
}
