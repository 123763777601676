import React, { FC, useRef } from 'react'
import { Box, Divider, Paper, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { useResizeObserver } from 'usehooks-ts'
import { useHotkeys } from 'react-hotkeys-hook'
import Song from './Song'
import { ISong } from '../models/song'
import {
  IPlaylist,
  ISongInPlaylist,
  ISongInPlaylistFirestore,
  PlaylistArtist
} from '../models/playlist'
import { transposeChord } from '../helpers/chords'
import SongArtistAvatar from './song/misc/SongArtistAvatar'

const NavTypography = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  fontWeight: 'bold',
  color: theme.palette.secondary.main
}))

const NavButton = styled(Button)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  flexGrow: 1,
  justifyContent: 'flex-start',
  height: '4em'
}))

export const NavButtonChord = styled(Typography)(() => ({
  textTransform: 'none',
  display: 'inline',
  backgroundColor: 'rgba(54, 218, 83, 0.3)',
  borderRadius: '2px',
  padding: '0.1em 0.3em',
  fontWeight: 'bold',
  fontSize: '1.2em'
}))

interface Props {
  playlist: IPlaylist
  song: ISongInPlaylist
  previousSong:
    | {
        song: ISong
        go: () => void
        transpose?: number
        capo?: number
        artist?: PlaylistArtist
      }
    | undefined
  nextSong:
    | {
        song: ISong
        go: () => void
        transpose?: number
        capo?: number
        artist?: PlaylistArtist
      }
    | undefined
  onSongInPlaylistSave: (songInPlaylist: Omit<ISongInPlaylistFirestore, 'id'>) => void
}

const prevAnimation = 'prevSlide'
const nextAnimation = 'nextSlide'

const SongInPlaylist: FC<Props> = (props) => {
  const prevLabelRef = useRef<HTMLDivElement>(null)
  const nextLabelRef = useRef<HTMLDivElement>(null)
  const prevButtonRef = useRef<HTMLButtonElement>(null)
  const nextButtonRef = useRef<HTMLButtonElement>(null)

  const { width: prevLabelWidth = 0 } = useResizeObserver({ ref: prevLabelRef })
  const { width: nextLabelWidth = 0 } = useResizeObserver({ ref: nextLabelRef })
  const { width: prevButtonWidth = 0 } = useResizeObserver({ ref: prevButtonRef })
  const { width: nextButtonWidth = 0 } = useResizeObserver({ ref: nextButtonRef })

  const previousSongButtonLabel = props.previousSong ? (
    <NavTypography
      ref={prevLabelRef}
      sx={{ animation: `${prevAnimation} 5s ease infinite` }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
      >
        <NavButtonChord>
          {transposeChord(props.previousSong.song.chordKey, props.previousSong.transpose ?? 0)}
          {props.previousSong.capo
            ? ` ${props.previousSong.capo > 0 ? '+' : ''}${props.previousSong.capo}`
            : ''}
        </NavButtonChord>
        {props.previousSong.song.title}
        {props.previousSong.artist && (
          <SongArtistAvatar
            artist={props.previousSong.artist}
            size={'small'}
          />
        )}
      </Stack>
    </NavTypography>
  ) : (
    ''
  )

  const nextSongButtonLabel = props.nextSong ? (
    <NavTypography
      ref={nextLabelRef}
      sx={{ animation: `${nextAnimation} 5s ease infinite` }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
      >
        <NavButtonChord>
          {transposeChord(props.nextSong.song.chordKey, props.nextSong.transpose ?? 0)}
          {props.nextSong.capo
            ? ` ${props.nextSong.capo > 0 ? '+' : ''}${props.nextSong.capo}`
            : ''}
        </NavButtonChord>
        {props.nextSong.song.title}
        {props.nextSong.artist && (
          <SongArtistAvatar
            artist={props.nextSong.artist}
            size={'small'}
          />
        )}
      </Stack>
    </NavTypography>
  ) : (
    ''
  )

  const onPreviousSongClick = () => {
    if (props.previousSong) {
      props.previousSong.go()
    }
  }

  const onNextSongClick = () => {
    if (props.nextSong) {
      props.nextSong.go()
    }
  }

  useHotkeys('ArrowLeft', () => onPreviousSongClick())
  useHotkeys('ArrowRight', () => onNextSongClick())

  return (
    <>
      <style>
        {`
          @keyframes ${prevAnimation} {
            0% {
              transform: translateX(0);
            }
            50% {
              transform: translateX(-${prevLabelWidth - prevButtonWidth + 10}px);
            }
            100% {
              transform: translateX(0);
            }
          }
          
          @keyframes ${nextAnimation} {
            0% {
              transform: translateX(0);
            }
            50% {
              transform: translateX(-${nextLabelWidth - nextButtonWidth + 10}px);
            }
            100% {
              transform: translateX(0);
            }
          }
        `}
      </style>
      <Song
        song={props.song}
        playlist={props.playlist}
        showAddToPlaylist={false}
        songInPlaylist={props.song}
        onSongInPlaylistSave={props.onSongInPlaylistSave}
        artist={props.song.artist ? props.song.artist : undefined}
      />

      <Divider sx={{ visibility: 'hidden', my: 3 }} />

      <Paper
        elevation={3}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000
        }}
      >
        <Stack direction={'row'}>
          <NavButton
            ref={prevButtonRef}
            variant={'text'}
            onClick={onPreviousSongClick}
          >
            {previousSongButtonLabel}
          </NavButton>

          <Divider
            orientation={'vertical'}
            flexItem
          />

          <NavButton
            ref={nextButtonRef}
            variant={'text'}
            onClick={onNextSongClick}
          >
            {nextSongButtonLabel}
          </NavButton>
        </Stack>
      </Paper>
    </>
  )
}

export default SongInPlaylist
