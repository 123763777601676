import { usePlaylistSongSyncSettings } from './usePlaylistSongSyncSettings'
import { useEffect, useState } from 'react'
import { onValue, ref, set } from 'firebase/database'
import { realtimeDatabase, realtimeDatabaseKeys } from '../firebase/config'
import { Unsubscribe } from '@firebase/firestore'

export const useMultipleDevicePlaylistSongSync = (
  playlistId: string | undefined,
  onSongIndexChange: (songIndex: number) => void
) => {
  const { settings: playlistSongSyncSettings } = usePlaylistSongSyncSettings()

  const [unsubscribeToSongChanges, setUnsubscribeToSongChanges] = useState<Unsubscribe>()

  /**
   * Sync song changes to other devices
   */
  const syncSongIndexToOtherDevices = (songIndex: number) => {
    if (!playlistId || !playlistSongSyncSettings.syncToOtherDevices) {
      return
    }

    void set(
      ref(realtimeDatabase, realtimeDatabaseKeys.playlistCurrentSongIndex(playlistId)),
      songIndex
    )
  }

  /**
   * Listen to song changes from other devices
   */
  useEffect(() => {
    if (unsubscribeToSongChanges) {
      unsubscribeToSongChanges()
    }

    if (!playlistId || !playlistSongSyncSettings.syncFromOtherDevices) {
      return
    }

    const currentSongIndexRef = ref(
      realtimeDatabase,
      realtimeDatabaseKeys.playlistCurrentSongIndex(playlistId)
    )

    const unsubscribe = onValue(currentSongIndexRef, (snapshot) => {
      const songIndex = snapshot.val() as number | null

      if (typeof songIndex === 'number') {
        onSongIndexChange(songIndex)
      }
    })

    setUnsubscribeToSongChanges(() => unsubscribe)

    return () => {
      unsubscribe()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistId, playlistSongSyncSettings.syncFromOtherDevices, onSongIndexChange])

  return { syncSongIndexToOtherDevices }
}
