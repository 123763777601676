import { FC, PropsWithChildren } from 'react'
import { TooltipProps } from '@mui/material/Tooltip/Tooltip'
import { Tooltip } from '@mui/material'

export interface Props extends TooltipProps {
  disabled: boolean
}

const ConditionalTooltip: FC<PropsWithChildren<Props>> = ({
  disabled,
  children,
  ...tooltipProps
}) => {
  if (disabled) {
    return children
  }

  return <Tooltip {...tooltipProps}>{children}</Tooltip>
}

export default ConditionalTooltip
