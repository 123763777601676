import { Divider, FormControlLabel, Stack, Switch, Typography } from '@mui/material'
import { usePlaylistScrollSyncSettings } from '../../hooks/usePlaylistScrollSyncSettings'
import { usePlaylistSongSyncSettings } from '../../hooks/usePlaylistSongSyncSettings'
import { usePlaylistCustomScrollSettings } from '../../hooks/usePlaylistCustomScrollSettings'
import NumberInput from '../common/inputs/NumberInput'

export const PlaylistSettings = () => {
  const { settings: playlistScrollSyncSettings, setSettings: setScrollSyncSettings } =
    usePlaylistScrollSyncSettings()
  const { settings: playlistSongSyncSettings, setSettings: setSongSyncSettings } =
    usePlaylistSongSyncSettings()

  const { scrollDelta, setScrollDelta } = usePlaylistCustomScrollSettings()

  const onSongSyncSettingsChange = (newSettings: typeof playlistSongSyncSettings) => {
    setSongSyncSettings(newSettings)

    const newScrollSyncSettings = { ...playlistScrollSyncSettings }
    let saveScrollSyncSettings = false
    // syncing scroll from other devices does not make sense if syncing song is disabled
    if (!newSettings.syncFromOtherDevices) {
      newScrollSyncSettings.syncFromOtherDevices = false
      saveScrollSyncSettings = true
    }

    // syncing scroll to other devices does not make sense if syncing song is disabled
    if (!newSettings.syncToOtherDevices) {
      newScrollSyncSettings.syncToOtherDevices = false
      saveScrollSyncSettings = true
    }

    if (saveScrollSyncSettings) {
      setScrollSyncSettings(newScrollSyncSettings)
    }
  }

  return (
    <Stack
      direction={'column'}
      spacing={2}
    >
      <Typography
        variant={'subtitle1'}
        sx={{ fontWeight: 'bold' }}
      >
        Synchronizovať aktuálnu pieseň medzi zariadeniami
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={playlistSongSyncSettings.syncFromOtherDevices}
            onChange={() =>
              onSongSyncSettingsChange({
                ...playlistSongSyncSettings,
                syncFromOtherDevices: !playlistSongSyncSettings.syncFromOtherDevices
              })
            }
          />
        }
        label='Synchronizovať z ostatných zariadení'
      />
      <FormControlLabel
        control={
          <Switch
            checked={playlistSongSyncSettings.syncToOtherDevices}
            onChange={() =>
              onSongSyncSettingsChange({
                ...playlistSongSyncSettings,
                syncToOtherDevices: !playlistSongSyncSettings.syncToOtherDevices
              })
            }
          />
        }
        label='Synchronizovať na ostatné zariadenia'
      />

      <Divider />

      <Typography
        variant={'subtitle1'}
        sx={{ fontWeight: 'bold' }}
      >
        Synchronizovať scroll aktuálnej piesne medzi zariadeniami
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={playlistScrollSyncSettings.syncFromOtherDevices}
            onChange={() =>
              setScrollSyncSettings({
                ...playlistScrollSyncSettings,
                syncFromOtherDevices: !playlistScrollSyncSettings.syncFromOtherDevices
              })
            }
            disabled={!playlistSongSyncSettings.syncFromOtherDevices}
          />
        }
        label='Synchronizovať z ostatných zariadení'
      />
      <FormControlLabel
        control={
          <Switch
            checked={playlistScrollSyncSettings.syncToOtherDevices}
            onChange={() =>
              setScrollSyncSettings({
                ...playlistScrollSyncSettings,
                syncToOtherDevices: !playlistScrollSyncSettings.syncToOtherDevices
              })
            }
          />
        }
        label='Synchronizovať na ostatné zariadenia'
        disabled={!playlistSongSyncSettings.syncToOtherDevices}
      />

      <Divider />

      <Typography
        variant={'subtitle1'}
        sx={{ fontWeight: 'bold' }}
      >
        Nastavenie dĺžky skoku pri posune (scroll-e)
      </Typography>
      <NumberInput
        value={scrollDelta}
        onChange={setScrollDelta}
        min={50}
        max={500}
        step={50}
      />
    </Stack>
  )
}
