import { useLocalStorage } from 'usehooks-ts'
import { localStorageKeys } from './useLatestPlaylists'

export interface PlaylistSongSyncSettings {
  syncFromOtherDevices: boolean
  syncToOtherDevices: boolean
}

export const usePlaylistSongSyncSettings = () => {
  const [settings, setSettings] = useLocalStorage<PlaylistSongSyncSettings>(
    localStorageKeys.playlistSongSyncSettings,
    {
      syncFromOtherDevices: false,
      syncToOtherDevices: false
    }
  )

  const toggleSyncFromOtherDevices = () => {
    setSettings({
      ...settings,
      syncFromOtherDevices: !settings.syncFromOtherDevices
    })
  }

  const toggleSyncToOtherDevices = () => {
    setSettings({
      ...settings,
      syncToOtherDevices: !settings.syncToOtherDevices
    })
  }

  return { settings, toggleSyncFromOtherDevices, toggleSyncToOtherDevices, setSettings }
}
