import { usePlaylistScrollSyncSettings } from '../../hooks/usePlaylistScrollSyncSettings'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import SettingsIcon from '@mui/icons-material/Settings'
import Dialog from '@mui/material/Dialog'
import { PlaylistSettings } from './PlaylistSettings'
import Box from '@mui/material/Box'

export const PlaylistScrollSyncToggleButtonWithDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  return (
    <>
      <IconButton onClick={() => setIsDialogOpen(true)}>
        <SettingsIcon />
      </IconButton>

      {isDialogOpen && (
        <Dialog
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
        >
          <Box sx={{ p: 2 }}>
            <PlaylistSettings />
          </Box>
        </Dialog>
      )}
    </>
  )
}
