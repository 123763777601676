import { useLocalStorage } from 'usehooks-ts'
import { localStorageKeys } from './useLatestPlaylists'

export const usePlaylistCustomScrollSettings = () => {
  const [scrollDelta, setScrollDelta] = useLocalStorage(
    localStorageKeys.playlistCustomUpDownScrollDelta,
    300
  )

  return { scrollDelta, setScrollDelta }
}
